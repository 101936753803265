.abs100x100 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero {
  position: relative;
  overflow: hidden;
  padding-bottom: 74.28%;
}

.hero__outer {
  position: relative;
  padding: 0px;
  margin: 0px;
}

.hero ol, .hero li {
  padding: 0;
  margin: 0;
}

.hero--bleed {
  padding-bottom: 0;
  height: 100vh; /* This is for browsers that don't support custom properties */
  height: calc(var(--vh, 1vh) * 100);
}

.hero__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hero__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
  visibility: hidden;
  transition: width .75s;
  transition-timing-function: cubic-bezier(.19,.53,.3,1);
}

.hero__item.active {
  z-index: 5;
  visibility: visible;
}

.hero__item.next {
  z-index: 4;
  visibility: visible;
}
.hero__item.next.transition {

}

.hero__item.previous {
  z-index: 3;
}

.hero__item.active.transition {
  width: 0;
}

.hero__item--inner {
    width: 100%;
}

.hero__item-video {
  background-size: cover;
}

.hero__item-embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

.hero__item-embed iframe, video.hero__item-embed , .hero__item-embed img {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero__item-text {
  position: absolute;
  top: 47%;
  left: 40%;
  right: 0;
  z-index: 10;
  color: $carousel-caption-color;
  transform: translate(-40%, -58%);
}

.hero__item-text--inner {
 position: relative;
 width: auto;
 margin-left: auto;
 margin-right: auto;
}

.hero__item-text--inner {
  opacity: 0;
  transform: translate(-5%, 0);
  transition: opacity .75s, transform .75s;
  transition-timing-function: cubic-bezier(.19,.53,.3,1);
}

.active .hero__item-text--inner, .transition .hero__item-text--inner {
  transform: translate(0, 0);
  opacity: 0.9;
}

.hero__item-text h2 {
  padding: 0;
  margin: 0;
  font-size: 2rem;
}

.hero__item-text h2 {
  font-size: 1.25rem;
}

@media only screen and (min-width: 355px) {

  .hero__item-text h2 {
    font-size: 1.5rem;
  }
  .hero__item-text--inner {
    width: 100%;
  }

}

@media only screen and (min-width: 551px) {

  .hero__item-text {
  top: 50%;
  }

  .hero__item-text h2 {
    font-size: 1.5rem;
  }
  .hero__item-text--inner {
    width: 80%;
  }

}
@media only screen and (min-width: 650px) {
  .hero__item-text--inner {
    width: 60%;
  }
}

@media only screen and (min-width: 756px) {
  .hero__item-text h2 {
    font-size: 2rem;
   }
}

@media only screen and (min-width: 865px) {
  .hero__item-text--inner {
    width: 50%;
  }
}

.red-dot-winner-flag {
  position: absolute;
  bottom: 9px;
  left: 0;
  // background-color: #f5f5f5;
  // background-color: rgba(245,245,245, 0.2);
  padding: 8px 9px 6px 8px;
  z-index:1002
}

.red-dot-winner-flag a {
  text-decoration: none;
  padding: 0;
  margin: 0;
}

.red-dot-winner-flag svg {
  width: 170px;
  height: auto;
  padding: 8px 9px 6px 0px;
}
@include media-breakpoint-up(md) {
  .red-dot-winner-flag svg {
    width: 180px;
    height: auto;
  }
}

@include media-breakpoint-up(lg) {
  .red-dot-winner-flag {
    padding: 8px 9px 6px 12px;
  }
  .red-dot-winner-flag svg {
    margin-left: 12px;
  }

}

/* Hero scroll */

.hero-scroll {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  transform-origin: bottom left;
  transform: rotate(-90deg) translate(50%, 200%);
  z-index: 20;
  text-decoration: none;

  &:hover {
    color: #fff;
    text-decoration: none;
  }
}
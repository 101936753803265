.carousel {
  position: relative;
  overflow: hidden;
}
.carousel-outer {
position: relative; overflow: hidden;
height: 0;
padding-bottom: 56.25%;
}

.dots {
  position: absolute;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 15;
  bottom: 0;
  right: 10px;
}

.dots__item {
  position: relative;
  font-size: 0;
  line-height: 0;
  display: block;
  width: 30px;
  height: 26px;
  margin-right: 3px;
  padding: 5px;
  cursor: pointer;
  border: 0;
  outline: none;
  background: transparent;
}

.dots__item::before {
    content: " ";
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: rgba(255,255,255,.4);
}

.dots__item--active::before {
    background-color: rgba(255,255,255,.9);
}
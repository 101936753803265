.menu {
  position: fixed;
  height: 100%;
  width: 0;
  top: 0;
  right: 0;
  overflow-x: hidden;
  z-index: 1040;

  .overlay {
    position: fixed;
    height: 0;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0);
    transition: opacity ease-in-out 0.3s;
    opacity: 0;
  }

  .menu-off-canvas {
    transition: transform 0.75s ease;
    transform: translateX(100%);
  }

  .menu-off-canvas {
    position: absolute;
    right: 0;
    width: 100%;
    // max-width: 100%;
    background: #fff;
    height: 100%;
    z-index: 2;
  }
@include media-breakpoint-up(sm) {
  .menu-off-canvas {
      width: 360px;
    }
    }

  // &.in-transition,
  &.is-active {

    .menu-off-canvas {
    transform: translateX(0);
    }

    .overlay {
      opacity: 1;
          }
  }

  &.is-active, &.in-transition, {
      width: 100%;
    .overlay {
      background-color: rgba(255,255,255,.7);
      bottom: 0;
      right: 0;
      height: 100%;

    }

  }
}

.menu-inner {
  display: flex;
  flex-direction: column;
}

.menu-content {
  flex: 1 0 auto;
}
.menu-footer {
  flex-shrink: 0;
  /* position: absolute;
  bottom: 0;
  margin-bottom: 1rem; */
  min-width: 100%;
  margin-bottom: 1rem;
}

.menu .menu-items {
  position: relative;
  font-size: 1.5rem;
  line-height: 1.35;
  margin-top: 85px;

  .list-group-item { padding: 0;}

  a {
    display: block;
    padding: .5rem 1.5rem;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &.active {
      text-decoration: underline;
    }
  }
}

.list-group-flush.menu-items:last-child {
  border-bottom: 1px solid;
}

.list-group-flush.menu-items:first-child {
  border-top: 1px solid;
}
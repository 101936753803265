@if index($hamburger-types, squeeze-custom) {
  /*
   * Squeeze
   */
  .hamburger--squeeze {
    .hamburger-inner {
      transition-duration: 0.1s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::before {
        transition: top 0.075s 0.12s ease,
                    opacity 0.075s ease,
                    color .3s ease;
      }

      &::after {
        transition: bottom 0.075s 0.12s ease,
                    transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19),
                    color .3s ease;
      }
    }

    &.is-active {
      .hamburger-inner {
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::before {
          top: 0;
          opacity: 0;
          transition: top 0.075s ease,
                      opacity 0.075s 0.12s ease,
                      color .3s ease;
        }

        &::after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.075s ease,
                      transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
                      color .3s ease;
        }
      }
    }
  }
    .menu .hamburger--squeeze {
      &.in-transition {
        .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
          background-color: $hamburger-active-layer-color;
        }
      }

    }
    .on-scroll.navbar {
      .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
        transition: background-color 0.075s ease;
        background-color: $hamburger-active-layer-color;
      }
    }
}

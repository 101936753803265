.fixed-top {
  overflow: hidden;
}
.navbar {
    transition: transform 0.5s ease;
    padding: 0;
}

.navbar .container-fluid {
              padding: 8px 16px;
              position: relative;
              transform: translate(0, 0);
              transition: transform 0.5s ease,
              background-color 0.5s ease
              ;
}

@include media-breakpoint-up(lg) {
  .navbar .container-fluid {
                padding: 8px 32px;
              }
}



// .navbar-hide {
//     top: -70px;
// }

.navbar.on-scroll:not(.hide) .container-fluid {
  background: #fff;
  background: rgba(255,255,255,0.9);
}

.navbar.hide  {
  z-index:0;
}

.navbar.transition {
  z-index: 1030;
}

.navbar.hide .container-fluid {
  transform: translate(0, -100%);
}

.on-scroll .logo-dedale {
  fill: #000;
}
.locales-list {
  position: absolute;
  right: 16px;
bottom: 23px;
  margin-bottom: 0;
  z-index: 21;
}
@include media-breakpoint-up(lg) {
.locales-list {
  right: 32px;
}
}

.locales-list .list-inline-item {
    color: #fff;
    padding:0 10px 0 6px;
    a {
      color: #fff;
      text-decoration: none;
      font-weight: 700;
    }
}

.locales-list .list-inline-item {
  display: block;
  float: left;
  margin: 0;
  border-top: 1px solid;
  border-left: 1px solid;
  border-bottom: 1px solid;
}

.locales-list .list-inline-item:last-child {
  border-right: 1px solid;
}

.locales-list .list-inline-item:hover {
  background-color: rgba(255,255,255,.3);
}

//

.menu {

  .locales-list {
    left: 24px;

  }
.locales-list .list-inline-item {
    color: #000;
    a {
      color: #000;
    }
}

.locales-list .list-inline-item:hover {
  background-color: rgba(0,0,0,.05);
}

}

// Material menu
.material-item .label {
  color:#fff;
  color:rgba(255,255,255,0.9);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  /* border: 2px solid rgba(255,255,255,.4); */
  padding: 8px 14px;
  text-align: center;
  // background-color: rgba(255,255,255,.2);
  min-width: 310px;
}

.material-item:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: .3;
  background-image: linear-gradient(90deg, rgba(29,29,29,0.00) 0%, rgba(29,29,29,0.3) 50%, rgba(29,29,29,0.00) 100%);
}
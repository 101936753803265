$hamburger-padding-x           : 16px;
$hamburger-padding-y           : 16px;
$hamburger-layer-width         : 36px;
// $hamburger-layer-height        : 4px !default;
// $hamburger-layer-spacing       : 6px !default;
$hamburger-layer-color         : #fff;
$hamburger-layer-border-radius : 0;
$hamburger-hover-opacity       : 1;
$hamburger-active-layer-color  : #000;
// $hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
// $hamburger-hover-use-filter   : false !default;
// $hamburger-hover-filter       : opacity(50%) !default;
// $hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Types (Remove or comment out what you don’t need)
$hamburger-types: (
squeeze-custom
//  3dx,
//  3dx-r,
//  3dy,
//  3dy-r,
//  3dxy,
//  3dxy-r,
//  arrow,
//  arrow-r,
//  arrowalt,
//  arrowalt-r,
//  arrowturn,
//  arrowturn-r,
//  boring,
//  collapse,
//  collapse-r,
//  elastic,
//  elastic-r,
//  emphatic,
//  emphatic-r,
//  minus,
//  slider,
//  slider-r,
//  spring,
//  spring-r,
//  stand,
//  stand-r,
//  spin,
//  spin-r,
//  squeeze,
//  vortex,
//  vortex-r
);
.aspect-ratio-box {
  height: 0;
  overflow: hidden;
  padding-top: 9 / 16 * 100%;
  position: relative;
}

.aspect-ratio-box--1-1 {
  height: 0;
  overflow: hidden;
  padding-top: 1 / 1 * 100%;
  position: relative;
}

.aspect-ratio-box--materials {
  height: 0;
  overflow: hidden;
  padding-top: 3 / 16 * 100%;
  background: white;
  position: relative;
}

@media (min-width: map-get($grid-breakpoints, 'md')){
.aspect-ratio-box--materials {
  padding-top: 2 / 16 * 100%;
}
}

.aspect-ratio-box-inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.img-cover {
  position: absolute;
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform ease .75s;
}

.img-cover:hover {
  transform: scale(104%);
}

.img-cover.no-effect:hover {
  transform: none;
}

.material-item:hover .img-cover {
  transform: scale(102%);
}


h2, .h2 {
font-size: 1.75rem;
  }

  h5, .h5 {
      font-size: 1rem;
  }
  @include media-breakpoint-up(sm) {
      h5, .h5 {
          font-size: 1.25rem;
        }
      }
@include media-breakpoint-up(md) {
    h2, .h2 {
      font-size: 2rem;
    }
    .lead {
      font-size: 1.25rem;
    }
}

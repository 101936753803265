@import "variables";

// Bootstrap
// @import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/grid";
// @import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
// @import "node_modules/bootstrap/scss/media";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/print";


@import "typography.scss";

@import "hamburgers-settings";
@import "node_modules/hamburgers/_sass/hamburgers/hamburgers";
@import "hamburger-squeeze-custom";
@import "hero.scss";
@import "aspect-ratio";



// Required
// @import "node_modules/bootstrap/scss/functions";
// @import "node_modules/bootstrap/scss/variables";
// @import "node_modules/bootstrap/scss/mixins";

// Optional
// @import "node_modules/bootstrap/scss/reboot";
// @import "node_modules/bootstrap/scss/type";
// @import "node_modules/bootstrap/scss/images";
// @import "node_modules/bootstrap/scss/code";
// @import "node_modules/bootstrap/scss/grid";

html {
  scroll-behavior: smooth;
}
html, body {
    overflow-x: hidden; /* Prevent scroll on narrow devices */
}

body {
  background: #f5f5f5;
}

header {
    background-color: #606060;
}

ul {
  padding-left: 18px;
}
/* Container */

.container {
    max-width: 1280px;
}

@include media-breakpoint-up(lg) {
.container.inner-content {
  padding-right: 24px;
  padding-left: 24px;
}
}

.max-width {
       max-width: 1440px;
}

a {
  text-underline-offset: .3rem;
}

// Grid

// Import CSS files
@import "menu-off-canvas";
@import "navbar";
@import "grid";
@import "carousel";
@import "materials";

@include media-breakpoint-up(lg) {
.hero__item-text h2 {
    font-size: 2.5rem;
}
}

.download-icon {
  width: 10px;
  height: 10px;
  margin-right: 6px;
  margin-bottom: 2px;
}

.link-icon {
  width: 10px;
  height: 10px;
  margin-right: 6px;
  margin-bottom: 2px;
  transform: rotate(-90deg);
}

// Arrow and animation
.arrow {
  fill: #fff;
  width:36px;
  margin-left: 16px;
  margin-top: 4px;
  animation: arrowTopDown 1s ease-out;
  animation-iteration-count: 5;
}

@keyframes arrowTopDown {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(10px, 0);
  }
}

.white {
  background-color: #fff;
}

.tile-txt {
  position: absolute;
  z-index: 2;
}

.tile-txt .small {
  font-size: $font-size-base;
}

.hamburger {
  position: absolute;
  right: 0;
  top: 16px;
  cursor: default;
}

#navbar .hamburger {
 top: 17px;
 right: 0;
}
@include media-breakpoint-up(lg) {
  #navbar .hamburger {
  right: 16px;
  }
}

.menu .hamburger {
    left: 4px;
}

.download-link {
  font-weight: $font-weight-bold;
  font-size: 1.1em;
}

.fade-box .lazyload,
 .fade-box .lazyloading {
  opacity: 0;
  transition: opacity 400ms,
  transform ease .75s;
}

.fade-box img.lazyloaded {
  transition: opacity 400ms,
  transform ease .75s;
  opacity: 1;
}


// Local language-switcher
@import "locale-list";

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  & iframe, & object, & embed, & video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}

.embed-container-1-1 {
  position: relative;
  padding-bottom: 100.3%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  & iframe, & object, & embed, & video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


// Boostrap 4

$theme-colors: (
  "primary": #1D1D1B,
  "secondary": #FFF,
  "danger": #ff4136
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 900px,
  lg: 992px,
  xl: 1200px
);

// Grid containers

// sm: 540px,
// md: 720px,
// lg: 960px,
// xl: 1140px




$font-family-sans-serif: "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif:      Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $font-family-sans-serif !default;

$navbar-brand-padding-y: 1rem;


$list-group-border-color: #000;
$list-group-item-padding-y:         .5rem;
$list-group-item-padding-x:         1.5rem;

$link-color:                              map-get($theme-colors, 'primary');
$link-decoration:                         underline !default;
$link-hover-color:                        map-get($theme-colors, 'primary'); // darken($link-color, 15%) !default;
$link-hover-decoration:                   underline !default;

// $theme-colors: map-remove($theme-colors, "success", "info", "danger");
// Required keys:  primary, success, danger

// $h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                   24px;
$lead-font-size:                 18px;
$lead-font-weight:               normal;
// $h3-font-size:                $font-size-base * 1.75 !default;
// $h4-font-size:                $font-size-base * 1.5 !default;
// $h5-font-size:                $font-size-base * 1.25 !default;
// $h6-font-size:                $font-size-base !default;

/* custom vars */
$custom-font-size: 20px;

/* hamburger */

$hamburger-layer-spacing: 7px;
